import { useLocationStack } from "core/LocationStack";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function useGoBack() {
  const locationStack = useLocationStack()
  const navigate = useNavigate()

  return useCallback(() => {
    if (locationStack.length > 1) {
      navigate(-1)
    } else {
      navigate('/', { replace: true })
    }
  }, [locationStack, navigate])
}