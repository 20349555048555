import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AppLayout from "core/AppLayout";
import { signOut } from 'firebase/auth'
import { auth } from "lib/firebase";

export default function Profile() {
  return (
    <AppLayout
      backable
      header="Profile"
    >
      <Container>
        <Button onClick={() => signOut(auth)}>
          Logout
        </Button>
      </Container>
    </AppLayout>
  )
}