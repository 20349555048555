import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { auth } from "./firebase";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
})

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser?.getIdToken()

  return {
    headers: {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`
      })
    }
  }
})

const link = authLink.concat(httpLink)

const cache = new InMemoryCache()

export const client = new ApolloClient({ link, cache })