import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <h1>404 | Page not found.</h1>
      <div>
        <Link to='/'>
          Back to home page
        </Link>
      </div>
    </div>
  )
}