import React, { createContext, useContext, useLayoutEffect, useMemo, useReducer } from "react";
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from "lib/firebase";
import type { User } from 'firebase/auth'

type Context = {
  loading: boolean,
  data: User | null | undefined
}

const context = createContext<Context>({
  loading: false,
  data: null
})

export const useUser = () => useContext(context)

export function UserContainer({ children }: React.PropsWithChildren<{}>) {
  const [user, setUser] = useReducer((_state: Context['data'], v: Context['data']) => v, undefined)

  const value = useMemo(() => ({
    loading: user === undefined,
    data: user
  }), [user])

  useLayoutEffect(() => onAuthStateChanged(auth, setUser), [])

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  )
}