import React, { useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import numberUtils from "utils/number";

const amountInputProps = {
  inputProps: {
    inputMode: 'numeric',
    pattern: '[0-9]*'
  },
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        $
      </InputAdornment>
    )
  }
} as const

export default function NumberTextField(props: TextFieldProps) {
  const [value, setValue] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    const [thousandsSeparator, decimalSeparator] = numberUtils.getNumberSeparators()

    if (
      !newValue ||
      newValue.endsWith(decimalSeparator)
    ) return setValue(newValue)

    const [thousandsPart, decimalPart] = newValue.split(decimalSeparator)
    const number = Number(thousandsPart.replaceAll(thousandsSeparator, ''))

    setValue(
      numberUtils.toLocaleString(number) +
      (decimalPart ? (decimalSeparator + decimalPart) : '')
    )
  }

  return (
    <TextField
      value={value}
      onChange={handleChange}
      inputProps={amountInputProps.inputProps}
      InputProps={amountInputProps.InputProps}
      {...props}
    />
  )
}