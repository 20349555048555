import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { formatCurrency } from "utils/currency";
import { useUser } from "./UserContext";
import ArrowRight from "icons/ArrowRight";
import ArrowLeft from "icons/ArrowLeft";

const badgeAnchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
} as const

interface Props {
  loan: CoreLoanFields
}

export default function Loan({ loan }: Props) {
  const { data: viewer } = useUser()
  const {
    amount,
    description,
    sourceCredentials,
    targetCredentials,
    sourceUser,
    targetUser
  } = loan

  const isLending = sourceUser?.uid === viewer?.uid
  const sourceTitle = sourceUser?.name || Object.values(sourceCredentials)[0]
  const targetTitle = targetUser?.name || Object.values(targetCredentials)[0]
  const title = isLending ? targetTitle : sourceTitle
  const avatar = (isLending ? targetUser?.avatar : sourceUser?.avatar) || undefined
  const icon = isLending ? <ArrowRight color='error' /> : <ArrowLeft color='success' />

  return (
    <ListItem
      secondaryAction={
        <Typography>
          {formatCurrency(amount)}
        </Typography>
      }
    >
      <ListItemAvatar>
        <Badge
          overlap="circular"
          anchorOrigin={badgeAnchorOrigin}
          badgeContent={icon}
        >
          <Avatar
            src={avatar}
            alt={title}
          />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={description}
      />
    </ListItem>
  )
}