import React from "react"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import BackButton from "./BackButton"

interface Props {
  header: string
  backable?: boolean
  actions?: JSX.Element
}

export default function AppLayout(props: React.PropsWithChildren<Props>) {
  const {
    header,
    backable,
    actions,
    children
  } = props

  return (
    <Stack height='100vh'>
      <AppBar
        position="relative"
        color='inherit'
        elevation={0}
      >
        <Toolbar>
          {backable && (
            <BackButton
              size='large'
              edge='start'
            />
          )}
          <Box flexGrow={1}>
            <Typography variant="h6" component='div'>
              {header}
            </Typography>
          </Box>
          {actions}
        </Toolbar>
      </AppBar>
      <Box
        flexGrow={1}
        overflow='auto'
      >
        {children}
      </Box>
    </Stack>
  )
}