import { QueryHookOptions, useQuery as useApolloQuery } from "@apollo/client"
import queries from 'gql/queries'

type QueryKey = keyof typeof queries

export default function useQuery<T extends QueryKey>(
  key: T,
  options?: QueryHookOptions<Queries[T]['data'], Queries[T]['vars']>
) {
  return useApolloQuery<Queries[T]['data'], Queries[T]['vars']>(queries[key], options)
}