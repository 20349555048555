import { gql } from "@apollo/client";
import fragments from "./fragments";

const queries = {
  VIEWER: gql`
    ${fragments.CORE_USER_FIELDS}
    query Viewer {
      viewer {
        ...CoreUserFields
      }
    }
  `,
  VIEWER_LOANS: gql`
    ${fragments.CORE_LOAN_FIELDS}
    query Viewer {
      viewer {
        id
        loans {
          ...CoreLoanFields
        }
      }
    }
  `
}

export default queries