import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContainer } from './core/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from 'lib/apollo';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'lib/mui';
import CssBaseline from '@mui/material/CssBaseline';
import LocationStack from 'core/LocationStack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <LocationStack>
          <UserContainer>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </UserContainer>
        </LocationStack>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
