import { DEFAULT_LOCALE, DEFAULT_CURRENCY } from "../constants"

interface FormatCurrencyOptions {
  locales: string | string[]
  currency: string
}

export const formatCurrency = (amount: number, options?: FormatCurrencyOptions) => {
  const {
    locales = DEFAULT_LOCALE,
    currency = DEFAULT_CURRENCY
  } = options || {}

  return amount.toLocaleString(locales, {
    style: 'currency',
    currency
  })
}