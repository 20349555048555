import Loading from "components/Loading";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "./UserContext";

export default function RequireAuth() {
  const location = useLocation()
  const { loading, data } = useUser()

  if (loading) return <Loading />
  if (!data) return <Navigate to='/login' state={{ prevLocation: location }} replace />

  return <Outlet />
}