import { gql } from "@apollo/client";

const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    uid
    name
    avatar
  }
`
const CORE_LOAN_FIELDS = gql`
  ${CORE_USER_FIELDS}
  fragment CoreLoanFields on Loan {
    id
    amount
    description
    sourceCredentials
    targetCredentials
    sourceUser {
      ...CoreUserFields
    }
    targetUser {
      ...CoreUserFields
    }
    loanedAt
    repaidAt
  }
`

const fragments = {
  CORE_USER_FIELDS,
  CORE_LOAN_FIELDS
}

export default fragments