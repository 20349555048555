import Container from "@mui/material/Container";
import AppLayout from "core/AppLayout";
import LoanForm from "core/LoanForm";

export default function NewLoan() {
  return (
    <AppLayout
      backable
      header="New Loan"
    >
      <Container>
        <LoanForm />
      </Container>
    </AppLayout>
  )
}