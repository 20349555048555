import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import LoanSkeleton from "components/LoanSkeleton"
import Loan from "./Loan"
import useQuery from "hooks/useQuery"

export default function Loans() {
  const { loading, data } = useQuery('VIEWER_LOANS')

  return (
    <Box>
      <Box my={2} mx={2}>
        <Typography variant="h5">
          History
        </Typography>
      </Box>
      <Divider variant='middle' />
      <List>
        {data?.viewer.loans.map(loan => (
          <Loan
            key={loan.id}
            loan={loan}
          />
        ))}
        {loading ? (
          [...Array(3)].map((_, idx) => (
            <LoanSkeleton key={idx} />
          ))
        ) : (
          data?.viewer.loans.length === 0 && (
            <ListItem>
              <ListItemText secondary='No loans yet' />
            </ListItem>
          )
        )}
      </List>
    </Box>
  )
}