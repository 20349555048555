import { Route, Routes } from "react-router-dom";
import RequireAuth from "core/RequireAuth";
import Home from 'elements/Home';
import Login from "elements/Login";
import NotFound from "elements/NotFound";
import Root from "elements/Root";
import Profile from "elements/Profile";
import NewLoan from "elements/NewLoan";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="login" element={<Login />} />
      <Route path="app" element={<RequireAuth />}>
        <Route path="" element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="loans">
          <Route path="new" element={<NewLoan />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}