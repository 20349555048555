import React, { createContext, useContext, useMemo, useRef } from "react"
import { Location, useLocation } from "react-router-dom"

const LocationStackContext = createContext<Location[]>([])

export const useLocationStack = () => useContext(LocationStackContext)

export default function LocationStack({ children }: React.PropsWithChildren<{}>) {
  const location = useLocation()
  const ref = useRef<Location[]>([])

  const value = useMemo(() => {
    ref.current.push(location)
    return ref.current
  }, [location])

  return (
    <LocationStackContext.Provider value={value}>
      {children}
    </LocationStackContext.Provider>
  )
}