import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ProfileCircle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.1205 13.53C12.1005 13.53 12.0705 13.53 12.0505 13.53C12.0205 13.53 11.9805 13.53 11.9505 13.53C9.68047 13.46 7.98047 11.69 7.98047 9.50998C7.98047 7.28998 9.79047 5.47998 12.0105 5.47998C14.2305 5.47998 16.0405 7.28998 16.0405 9.50998C16.0305 11.7 14.3205 13.46 12.1505 13.53C12.1305 13.53 12.1305 13.53 12.1205 13.53ZM12.0005 6.96998C10.6005 6.96998 9.47047 8.10998 9.47047 9.49998C9.47047 10.87 10.5405 11.98 11.9005 12.03C11.9305 12.02 12.0305 12.02 12.1305 12.03C13.4705 11.96 14.5205 10.86 14.5305 9.49998C14.5305 8.10998 13.4005 6.96998 12.0005 6.96998Z" />
      <path d="M12.0008 22.7501C9.31081 22.7501 6.74081 21.7501 4.75081 19.9301C4.57081 19.7701 4.49081 19.5301 4.51081 19.3001C4.64081 18.1101 5.38081 17.0001 6.61081 16.1801C9.59081 14.2001 14.4208 14.2001 17.3908 16.1801C18.6208 17.0101 19.3608 18.1101 19.4908 19.3001C19.5208 19.5401 19.4308 19.7701 19.2508 19.9301C17.2608 21.7501 14.6908 22.7501 12.0008 22.7501ZM6.08081 19.1001C7.74081 20.4901 9.83081 21.2501 12.0008 21.2501C14.1708 21.2501 16.2608 20.4901 17.9208 19.1001C17.7408 18.4901 17.2608 17.9001 16.5508 17.4201C14.0908 15.7801 9.92081 15.7801 7.44081 17.4201C6.73081 17.9001 6.26081 18.4901 6.08081 19.1001Z" />
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" />
    </SvgIcon>
  )
}