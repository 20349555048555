import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    h5: {
      fontWeight: "bold"
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'standard',
        margin: 'normal'
      }
    }
  }
})