import { DEFAULT_LOCALE } from "../constants"

const toLocaleString = (value: number, locale: string = DEFAULT_LOCALE) => value.toLocaleString(locale)

// [thousands, decimal]
const getNumberSeparators = (locale: string = DEFAULT_LOCALE) => toLocaleString(0.1, locale)  === '0,1'
  ? ['.', ',']
  : [',', '.']


const toNumber = (value: string, locale: string = DEFAULT_LOCALE) => {
  const [thousandsSeparator, decimalSeparator] = getNumberSeparators()
  return Number(value
    .replaceAll(thousandsSeparator, '')
    .replace(decimalSeparator, '.')
  )
}

const numberUtils = {
  toLocaleString,
  getNumberSeparators,
  toNumber
}

export default numberUtils