import { PROVIDERS } from "constants/auth"
import { signInWithPopup } from "firebase/auth"
import useLocationState from "hooks/useLocationState"
import { auth } from "lib/firebase"
import { Location, useNavigate } from "react-router-dom"

interface State {
  prevLocation: Location
}

export default function Login() {
  const navigate = useNavigate()
  const state = useLocationState<State>()

  const handleLoginWith = async (providerKey: keyof typeof PROVIDERS) => {
    const provider = PROVIDERS[providerKey]
    await signInWithPopup(auth, provider)
    navigate(state?.prevLocation.pathname || '/', { replace: true })
  }

  return (
    <button onClick={() => handleLoginWith('google')}>
      Login with Google
    </button>
  )
}