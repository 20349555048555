import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const inputProps = {
  sx: {
    py: 1,
    fontSize: 14
  }
}

export default function UserSelect() {
  return (
    <Box>
      <TextField
        variant='outlined'
        placeholder="Email or phone"
        inputProps={inputProps}
      />
    </Box>
  )
}