import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import useGoBack from "hooks/useGoBack"
import Back from "icons/Back"

export default function BackButton(props: IconButtonProps) {
  const goBack = useGoBack()

  return (
    <IconButton
      onClick={goBack}
      {...props}
    >
      <Back />
    </IconButton>
  )
}