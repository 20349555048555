import Fab from "@mui/material/Fab"
import IconButton from "@mui/material/IconButton"
import AppLayout from "core/AppLayout"
import Loans from "core/Loans"
import Add from "icons/Add"
import ProfileCircle from "icons/ProfileCircle"
import { Link } from "react-router-dom"

const fabSx: Sx = {
  position: 'fixed',
  bottom: 16,
  right: 16
}

export default function Home() {
  return (
    <AppLayout
      header='Home'
      actions={
        <IconButton
          component={Link}
          to='/app/profile'
          size='large'
          edge='end'
        >
          <ProfileCircle />
        </IconButton>
      }
    >
      <Loans />
      <Fab
        color='primary'
        sx={fabSx}
        component={Link}
        to='/app/loans/new'
      >
        <Add />
      </Fab>
    </AppLayout>
  )
}
