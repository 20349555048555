import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Skeleton from "@mui/material/Skeleton"

export default function LoanSkeleton() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant='circular' width={40} height={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton />}
        secondary={<Skeleton width='80%' />}
      />
    </ListItem>
  )
}