import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import NumberTextField from "components/NumberTextField";
import UserSelect from "./UserSelect";

export default function LoanForm() {
  return (
    <Box>
      <UserSelect />
      <NumberTextField
        name='loan[amount]'
        label='Amount'
        placeholder='100.000'
      />
      <TextField
        name='loan[description]'
        label='Description'
      />
    </Box>
  )
}